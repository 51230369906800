import './Logos.css';

const logos = [
  { src: '/NatureServe-logo-H.jpg', alt: 'NatureServe', url: 'https://www.natureserve.org/'  },
  { src: '/MW-CASC-Logo.png', alt: 'MIDWEST Climate Adaption Science Center', url: 'https://mwcasc.umn.edu/' },
  { src: '/NC CASC logo.png', alt: 'NORTH CENTRAL Climate Adaption Science Center', url: 'https://nccasc.colorado.edu/' },
  { src: '/NE CASC logo.png', alt: 'NORTHEAST Climate Adaption Science Center', url: 'https://necasc.umass.edu/' },
  { src: '/NWCASC-color-horiz.png', alt: 'NORTHWEST Climate Adaption Science Center', url: 'https://nwcasc.uw.edu/' },
  { src: '/SC CASC Logo Black.png', alt: 'SOUTH CENTRAL Climate Adaption Science Center', url: 'https://southcentralclimate.org/' },
  { src: '/SE CASC Logo small.png', alt: 'SOUTHEAST Climate Adaption Science Center', url: 'https://secasc.ncsu.edu/' },
  { src: '/SWCASC with text_smaller margins.jpg', alt: 'SOUTHWEST Climate Adaption Science Center', url: 'https://www.swcasc.arizona.edu/' },
  { src: '/USGS-green-transparent.png', alt: 'USGS Science for a changing world', url: 'https://www.usgs.gov/'}
]

function Logos() {
  return (
    <div className="logo-section">
      {logos.map(({ src, alt, url }) => (
        <a href={url} target="_blank" rel='noopener noreferrer'>
          <img src={src} alt={alt} key={alt||src} />
        </a>
      ))}
  </div>
  )
}

export default Logos;