import React from 'react';
import * as PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { withTranslation } from 'react-i18next';

function QuestionHelpDialog({
  t, question, kingdom, allowedValues, isAdaptiveCapacity, open, handleClose
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={`${question}-help-dialog-title`}
      aria-describedby={`${question}-help-dialog-description`}
    >
      <DialogTitle id={`${question}-help-dialog-title`}>{t([`questions.${question}_${kingdom}.title`, `questions.${question}.title`])}</DialogTitle>
      <DialogContent>
        <DialogContentText id={`${question}-help-dialog-description`}>
          {t([`questions.${question}_${kingdom}.description`, `questions.${question}.description`])}
        </DialogContentText>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='left'>Category</TableCell>
                <TableCell align='left'>Category definition</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allowedValues.map((value) =>
                <TableRow key={value}>
                  <TableCell align='left'>{t(`scales.${value}`)} {isAdaptiveCapacity ? "adaptive capacity" : "vulnerability"}:</TableCell>
                  <TableCell align='left'>{t([`questions.${question}_${kingdom}.${value}`, `questions.${question}.${value}`])}</TableCell>
                </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

QuestionHelpDialog.propTypes = {
  question: PropTypes.string.isRequired,
  kingdom: PropTypes.string.isRequired,
  allowedValues: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  isAdaptiveCapacity: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withTranslation('translations')(QuestionHelpDialog);
