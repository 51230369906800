const kingdoms = [
  'animalia',
  'plantae',
  'fungae',
];

const majorTaxonomicGroups = [
  'vascularPlant',
  'nonvascularPlant',
  'fungiLichen',
  'invertInsect',
  'invertMollusk',
  'fish',
  'amphibian',
  'reptile',
  'mammal',
  'bird',
];

export { kingdoms, majorTaxonomicGroups };
