import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useAuth } from '../../AuthContext';

const dataCells = [
  "geographicArea",
  "assessor",
  "scientificName",
  "commonName",
  "population",
  "kingdom",
  "majorTaxonomicGroup",
  "gRank",
  "sRank",
  "assessmentNotes",
  "author",
];

function LoadDialog({
  t, open, setOpen, setExistingAssessment, setAllFormState, setSharedUsers
}) {
  const { jwtToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadErrorMessage, setLoadErrorMessage] = useState('');

  const [allAssessments, setAllAssessments] = useState([]);
  useEffect(() => {
    if (jwtToken && open) {
      setLoadErrorMessage('');
      setLoading(true);
      fetch(`${process.env.REACT_APP_CCVI_API_URL}/assessment`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${jwtToken}`, 'Content-Type': 'application/json' },
        })
        .then((res) => {
          if (!res.ok) {
            setLoadErrorMessage(`${res.status} ${res.statusText}`);
          }

          return res.json();
        })
        .then((body) => {
          if (body.msg) {
            setLoadErrorMessage((curVal) => curVal + `: ${body.msg}`);
          } else {
            const foundAllAssessments = body.data;
            setAllAssessments(foundAllAssessments);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoadErrorMessage(e);
          setLoading(false);
        }
      );
    }
  }, [jwtToken, open]);

  const [selectedAssessment, setSelectedAssessment] = useState({});

  const rowsPerPage = 5;

  const [page, setPage] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');

  const resetLoadDialog = () => {
    setSelectedAssessment({});
    setPage(0);
    setSearchTerm('');
  };

  const load = (author, assessmentId) => {
    setLoadErrorMessage('');
    setLoading(true);
    fetch(`${process.env.REACT_APP_CCVI_API_URL}/assessment/${author}/${assessmentId}`,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${jwtToken}`, 'Content-Type': 'application/json' },
      })
      .then((res) => {
        if (res.ok) {
          setOpen(false);
          resetLoadDialog();
          setExistingAssessment(true);
        } else {
          setLoadErrorMessage(`${res.status} ${res.statusText}`);
        }

        return res.json();
      })
      .then((body) => {
        if (body.msg) {
          setLoadErrorMessage((curVal) => curVal + `: ${body.msg}`);
        } else {
          setSharedUsers(body.data.sharedWith || []);
          setAllFormState(body.data);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoadErrorMessage(e);
        setLoading(false);
      });
  };



  const renderAssessment = useCallback((assessment) => {
    const renderedAssessment = { ...assessment };
    if (renderedAssessment.kingdom) {
      renderedAssessment.kingdom = t(`kingdoms.${renderedAssessment.kingdom}`);
    }

    if (renderedAssessment.majorTaxonomicGroup) {
      renderedAssessment.majorTaxonomicGroup = t(`majorTaxonomicGroups.${renderedAssessment.majorTaxonomicGroup}`);
    }

    return renderedAssessment;
  }, [t]);

  const shownAssessments = useMemo(() => {
    if (!searchTerm) {
      return allAssessments;
    }

    const renderedAssements = allAssessments.map((assessment) => renderAssessment(assessment));
    const assessmentsToShow = [];
    renderedAssements.forEach((assessment, idx) => {
      let show = false;
      for (const cell of dataCells) {
        if (!assessment[cell]) {
          continue;
        }
        const found = assessment[cell].toLowerCase().includes(searchTerm);
        if (found) {
          show = true;
          break;
        }
      }

      if (show) {
        assessmentsToShow.push(allAssessments[idx]);
      }
    });

    // In case we're further along in pages when the user starts searching, we don't leave them with a blank page.
    setPage(0);

    return assessmentsToShow;
  }, [searchTerm, allAssessments, renderAssessment]);

  useEffect(() => {
    if (!selectedAssessment?.assessmentUuid) return;
    if (!shownAssessments.find((assessment) => assessment.assessmentUuid === selectedAssessment.assessmentUuid)) {
      setSelectedAssessment({});
    }
  }, [shownAssessments, selectedAssessment]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='load-dialog-title'
      aria-describedby='load-dialog-description'
      fullScreen
    >
      <DialogTitle id='load-dialog-title'>{t('loadDialog.loadAssessment')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='load-dialog-description'>{t('loadDialog.loadDescription')}</DialogContentText>
        <TextField
          variant='standard'
          label={t('loadDialog.searchLabel')}
          fullWidth
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)} />
        {shownAssessments.length === 0
          ? <Typography>{t('loadDialog.noAssessments')}</Typography>
          : <>
            <TableContainer>
              <Table size='medium' sx={{ minWidth: 750 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('loadDialog.selected')}</TableCell>
                    {dataCells.map((cell) => {
                      return <TableCell key={`header.${cell}`}>{cell === 'author' ? t('loadDialog.author') : t(`assessmentForm.${cell}`)}</TableCell>
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shownAssessments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((assessment) => {
                    const renderedAssessment = renderAssessment(assessment);
                    const checked = selectedAssessment.assessmentUuid === assessment.assessmentUuid;

                    return <TableRow
                      hover
                      onClick={(() => setSelectedAssessment(assessment))}
                      role='checkbox'
                      key={assessment.assessmentUuid}
                      aria-checked={checked}
                      selected={checked}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding='checkbox' align='center'>
                        <Checkbox checked={checked} />
                      </TableCell>
                      {dataCells.map((cell) => { return <TableCell key={`${assessment.assessmentUuid}.${cell}`}>{renderedAssessment[cell]}</TableCell> })}
                    </TableRow>;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component='div'
              count={shownAssessments.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)} />
          </>}
      </DialogContent>
      {loadErrorMessage ? <Alert severity='error'>{t('loadDialog.couldNotLoad', { error: loadErrorMessage })}</Alert> : null}
      {loading
        ? <LinearProgress />
        : <DialogActions>
          <Button size='large' onClick={() => { setOpen(false); resetLoadDialog(); }}>{t('loadDialog.cancel')}</Button>
          <Button size='large' onClick={() => load(selectedAssessment.author, selectedAssessment.assessmentUuid)} disabled={!selectedAssessment.assessmentUuid}>{t('loadDialog.load')}</Button>
        </DialogActions>
      }
    </Dialog>
  );
}

LoadDialog.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setExistingAssessment: PropTypes.func.isRequired,
  setAllFormState: PropTypes.func.isRequired,
  setSharedUsers: PropTypes.func.isRequired,
};

export default withTranslation('translations')(LoadDialog);
