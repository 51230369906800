import React from 'react';
import * as PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import PreviousNextButtons from './PreviousNextButtons';
import './Footer.css';

function Footer({
  t, tabCount, tabIndex, setTabIndex, cannotContinue
}) {
  return (
    <Paper sx={{ width: '100%', zIndex: 999 }} elevation={3}>
      <PreviousNextButtons tabCount={tabCount} tabIndex={tabIndex} setTabIndex={setTabIndex} cannotContinue={cannotContinue} />
      <Typography fontSize={12} mt={1} ml={3} mr={3}>{t('footer.provisionalDisclaimer')}</Typography>
      <div className="copyright-info">
        {`${t('footer.Copyright', { year: new Date().getFullYear() })} `}
        <a
          className="subtle-link"
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.natureserve.org"
        >
          NatureServe
        </a>
        {' | '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={`https://explorer.natureserve.org/AboutTheData/UseGuidelinesCitations`}
          className="subtle-link"
        >
          {t('footer.Terms of Use & Citation')}
        </a>
      </div>
    </Paper>
  );
}

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  tabCount: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
  cannotContinue: PropTypes.bool.isRequired,
};

export default withTranslation('translations')(Footer);
