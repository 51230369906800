import React from 'react';
import './SimpleTabPanel.css';

function SimpleTabPanel(props) {
  const { children, value, index, unmountWhenHidden=true, ...other } = props;
  return <div
    role='tabpanel'
    className='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    {...other}
  >
    {unmountWhenHidden ? value === index && (children) : children}
  </div>
}

export default SimpleTabPanel;
