import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, Alert } from '@mui/material';
import Graphic from '@arcgis/core/Graphic'
import './ShapefileUploader.css';
import request from '@arcgis/core/request'
import { polygonSymbol } from './MapConstants';
import Tooltip from '@mui/material/Tooltip';

const fiveMegaBytes = 5000000

function ShapefileUploader({ onUploadGraphics }) {
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState('');
  const [filename, setFilename] = useState('');
  const [displayingFilename, setDisplayingFilename] = useState(false);

  const { t } = useTranslation('translations');

  /** takes a shapefile event, sends out the generate request, and sets uploadedJson  */
  const handleUploadFile = (event) => {
    setDisplayingFilename(false);
    if (!event.target?.files[0]) return;
    const { name, size, type } = event.target.files[0];
    if (size > fiveMegaBytes) {
      setError('fileSizeExceeded');
    } else if (type !== 'application/x-zip-compressed') {
      setError('invalidFileFormat');
    } else {
      setError(null);
      setUploading(true);
      setFilename(name);

      let path = event.target.value;
      const params = {
        name: path
      };
      const myContent = {
        filetype: 'shapefile',
        publishParameters: JSON.stringify(params),
        f: 'json'
      };

      const genFeatureColUrl = new URL(process.env.REACT_APP_GEN_FEATURE_URL);
      // add name and value pairs to search params of url, according to mycontent object:
      Object.keys(myContent).forEach(key => {
        genFeatureColUrl.searchParams.append(key, myContent[key]);
      });

      request(`${genFeatureColUrl}`, {
        query: myContent,
        body: document.getElementById('shapefileUploadForm'),
        responseType: 'json'
      })
      .then((uploadedJson) => {
        setUploading(false);
        const jsonLayers = uploadedJson?.data?.featureCollection?.layers;
        if (!jsonLayers.length) {
          setError('noLayersFound')
        }
        let allGraphics = [];
        jsonLayers.forEach((jsonLayer) => {
          if (jsonLayer.featureSet.geometryType === "esriGeometryPolygon") {
            const graphics = jsonLayer.featureSet.features.map((feature) => {
              const graphic = Graphic.fromJSON(feature);
              graphic.symbol = polygonSymbol;
              return graphic;
            })
            allGraphics = allGraphics.concat(graphics);
          } else {
            setError('ignoringNonPolygonLayers');
          }
        })
        setDisplayingFilename(true);
        onUploadGraphics(allGraphics)
        // console.log('uploadedJson: ', uploadedJson);
      })
      .catch(err => {
        console.error('error: ', err);
        const errorMsg = (err.details?.messages && err.details.messages[0]) || err.message;
        setError(errorMsg);
        setUploading(false);
      });
    }
  }

  return (
    <form encType="multipart/form-data" method="post" id="shapefileUploadForm">
      <Tooltip title={t('uploader.uploadTooltip')}>
        <Button
          sx={{ marginBottom: 1.5 }}
          tabIndex={-1}
          component="label" // clicking on a label fires click event for button within
        >
          {t('uploader.uploadShapefile')}
          <input
            className="shapefile-upload-input"
            type="file"
            name="file"
            accept=".zip"
            onChange={handleUploadFile}
          />
        </Button>
      </Tooltip>
      {displayingFilename && (
        <Alert severity="success" onClose={() => setDisplayingFilename(false)}>
          {t('uploader.fileUploaded', { filename })}
        </Alert>
      )}
      {uploading && (<Alert severity="info">{t('uploader.uploadingShapefile')}</Alert>)}
      {error && (<Alert severity="error">{t(`uploader.errors.${error}`)}</Alert>)}
    </form>
  );
}

ShapefileUploader.propTypes = {
  /** callback that takes an array polygon Graphics in a shapefile when uploaded  */
  onUploadGraphics: PropTypes.func
}

export default ShapefileUploader;