import React from 'react';
import { I18nextProvider } from 'react-i18next';
import Modules from './components/Modules';
import i18n from './i18n';
import {MapViewContextProvider} from "react-mapcore-library";
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1f417d'
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <MapViewContextProvider>
          <Modules />
        </MapViewContextProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
