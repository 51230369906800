const QUESTION_UNKNOWN_VALUE = -1;
const QUESTION_DISABLED_VALUE = -2;

const ADAPTIVE_CAPACITY_MODULES = [
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
];

const RCP_VALUES = ['mean_rcp85', 'mean_rcp45'];

const INSUFFICIENT_EVIDENCE = -1;

const exposureStatuses = {
  NO_AREA_TO_CALCULATE: 'NO_AREA_TO_CALCULATE',
  READY_TO_CALCULATE: 'READY_TO_CALCULATE',
  CALCULATING: 'CALCULATING',
  SUCCESSFULLY_CALCULATED: 'SUCCESSFULLY_CALCULATED',
  FAILED_CALCULATION: 'FAILED_CALCULATION'
}

export { QUESTION_UNKNOWN_VALUE, QUESTION_DISABLED_VALUE, ADAPTIVE_CAPACITY_MODULES, INSUFFICIENT_EVIDENCE, RCP_VALUES, exposureStatuses };
