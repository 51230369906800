import { useState, useContext } from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import Sketch from "@arcgis/core/widgets/Sketch";
import {
  MapViewContext,
  MapContainer,
  Sidebar,
  EsriWidgetContainer } from 'react-mapcore-library';
import ShapefileUploader from './ModuleA/ShapfileUploader';
import Exposure from './ModuleA/Exposure';
import './ModuleA.css';
import LayerList from "@arcgis/core/widgets/LayerList.js";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Legend from "@arcgis/core/widgets/Legend";
import Expand from "@arcgis/core/widgets/Expand";
import { exposureStatuses } from '../../utils/Consts';
import USStateSelector from './ModuleA/USStateSelector';
import AddOrReplaceDialog from './ModuleA/AddOrReplaceDialog';

const ccviMapServerUrl = 'https://geodata.natureserve.org/hosting/rest/services/CCVI/CCVI/MapServer';
const getCCVISublayer = (title, id) => {
  return new MapImageLayer({ url: ccviMapServerUrl, listMode: "hide-children", title, sublayers: [{ id, title: ' ' }] })
}
function ModuleA({
  t,
  graphicsLayer,
  exposureResults,
  setExposureResults,
  setExposureRcpValue,
  exposureRcpValue,
  exposureStatus,
  setExposureStatus,
}) {
  const {mapView} = useContext(MapViewContext);

  const [groupLayer] = useState(() => {
    return new GroupLayer({
      visible: false,
      visibilityMode: "exclusive",
      title: t('layerTitles.groupLayer'),
      layers: [
        getCCVISublayer(t('layerTitles.exposureRCP4.5'), 1),
        getCCVISublayer(t('layerTitles.exposureRCP8.5'), 0)
      ]
    });
  });

  const [graphicsToAddOrReplace, setGraphicsToAddOrReplace] = useState(null);

  const addGraphics = (graphics) => {
    graphicsLayer.graphics.push(...graphics);
    mapView.goTo(graphicsLayer.graphics);
    setExposureStatus(exposureStatuses.READY_TO_CALCULATE);
  }

  const tentativelyAddGraphics = (graphics) => {
    if (graphicsLayer.graphics.length) {
      setGraphicsToAddOrReplace(graphics);
    } else {
      addGraphics(graphics);
    }
  }

  return (
    <div className='module-a'>
        <div className='module-a-map-context'>
          <Sidebar t={t}>
            <div className="sidebar-content">
              <div className="sidebar-instructions">{t('exposure.instructions')}</div>
              <ShapefileUploader onUploadGraphics={tentativelyAddGraphics} />
              <EsriWidgetContainer
                EsriConstructor={Sketch}
                initialProperties={{
                  layer: graphicsLayer,
                  availableCreateTools: ['polygon'],
                  creationMode: "single",
                  defaultUpdateOptions: { enableRotation: false, enableScaling: false, tool: 'reshape' },
                  tooltipOptions: { enabled: true }
                }}
                eventCallbacks={{
                  create: (event) => {
                    if (event.state === 'complete') {
                      setExposureStatus(exposureStatuses.READY_TO_CALCULATE)
                    }
                  },
                  delete: (_) => setExposureStatus(
                    graphicsLayer.graphics.length ?
                    exposureStatuses.READY_TO_CALCULATE :
                    exposureStatuses.NO_AREA_TO_CALCULATE
                  ),
                  redo: (_) => setExposureStatus(exposureStatuses.READY_TO_CALCULATE),
                  undo: (_) => setExposureStatus(exposureStatuses.READY_TO_CALCULATE),
                  update: (event) => {
                    if (event.state === 'active' && exposureStatus !== exposureStatuses.READY_TO_CALCULATE) {
                      setExposureStatus(
                        graphicsLayer.graphics.length ?
                        exposureStatuses.READY_TO_CALCULATE :
                        exposureStatuses.NO_AREA_TO_CALCULATE
                      );
                    }
                  }
                }}
              />
              <USStateSelector onSelectGraphic={(graphic) => tentativelyAddGraphics([graphic])} />
              <AddOrReplaceDialog
                open={Boolean(graphicsToAddOrReplace)}
                onReplace={() => {
                  graphicsLayer.graphics.removeAll();
                  addGraphics(graphicsToAddOrReplace);
                  setGraphicsToAddOrReplace(null);
                }}
                onAdd={() => {
                  addGraphics(graphicsToAddOrReplace);
                  setGraphicsToAddOrReplace(null);
                }}
                onCancel={() => {
                  setGraphicsToAddOrReplace(null);
                }}
              />
              <hr className="sidebar-separator" />
              <Exposure
                t={t}
                onGetAllResults={(rcpResults) => {
                  setExposureResults(rcpResults.map(result => Number(result.value)));
                }}
                setExposureRcpValue={setExposureRcpValue}
                exposureRcpValue={exposureRcpValue}
                exposureResults={exposureResults}
                graphicsCollection={graphicsLayer.graphics}
                status={exposureStatus}
                setStatus={setExposureStatus}
              />
              <hr className="sidebar-separator" />
              <EsriWidgetContainer
                EsriConstructor={LayerList}
              />
              <p>
                <Trans t={t} i18nKey="exposure.instructionsForGuidelinesDocument">
                  <a href={process.env.REACT_APP_GUIDELINES_DOCUMENT}  target="_blank" rel="noreferrer"> </a>
                </Trans>
              </p>
            </div>
          </Sidebar>
          <MapContainer
            mapViewProps={
              {
                popup: {
                  defaultPopupTemplateEnabled: true,
                  disableDefaultUI: true
                },
                extent: {
                  xmin: -126.902,
                  ymin: 23.848,
                  xmax: -65.73,
                  ymax: 50.15
                }
              }
            }
            mapProps={{
              basemap: 'topo-vector',
              layers: [groupLayer, graphicsLayer]
            }}
            onLoad={(view) => {
              if (!!graphicsLayer.graphics.length) {
                view.goTo(graphicsLayer.graphics);
              }
              const legendWidget = new Legend({ view });
              const legendExpand = new Expand({
                expandTooltip: t('exposure.legendTooltip'),
                view,
                content: legendWidget
              })
              view.ui.add(legendExpand, 'bottom-right');
            }}
          />
        </div>
    </div>
  );
}

ModuleA.propTypes = {
  t: PropTypes.func.isRequired,
  graphicsLayer: PropTypes.instanceOf(GraphicsLayer).isRequired,
  exposureResults: PropTypes.arrayOf(PropTypes.number).isRequired,
  setExposureResults: PropTypes.func.isRequired,
  exposureStatus: PropTypes.string.isRequired,
  setExposureStatus: PropTypes.func.isRequired
};

export default withTranslation('translations')(ModuleA);
