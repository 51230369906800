import * as PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Autocomplete } from '@mui/material';
import { executeQueryJSON } from '@arcgis/core/rest/query'
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Graphic from '@arcgis/core/Graphic'
import { polygonSymbol } from './MapConstants';

const usBoundariesFeatureUrl = process.env.REACT_APP_US_BOUNDARIES_FEATURE_URL;

function USStateSelector ({ onSelectGraphic }) {
  const [usStateOptions, setUsStateOptions] = useState([]);
  const { t } = useTranslation('translations');
  useEffect(() => {
    executeQueryJSON(usBoundariesFeatureUrl, {
      where: "STATE_NAME NOT IN ('Alaska', 'Hawaii')",
      outFields: "STATE_NAME",
      returnGeometry: false
    }).then((result) => {
      setUsStateOptions(result.features
        .map(feature => ({ label: feature.attributes.STATE_NAME }))
        .sort((a, b) => a.label - b.label)
      );
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  const handleStateSelect = (selectedOption) => {
    executeQueryJSON(usBoundariesFeatureUrl, {
      where: `STATE_NAME='${selectedOption?.label}'`,
      outFields: "STATE_NAME",
      returnGeometry: true
    }).then((result) => {
      if (result.features?.length !== 1) {
        console.error('Unexpected result encountered querying geometry for ', selectedOption?.label);
      } else {
        const generalizedGeometry = geometryEngine.generalize(result.features[0].geometry, .01, true);
        const stateGraphic = new Graphic({
          geometry: generalizedGeometry,
          symbol: polygonSymbol
        });
        onSelectGraphic(stateGraphic);
      }
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <Autocomplete
      renderInput={(params) => <TextField {...params} label={t('usStateSelect.label')} />}
      options={usStateOptions}
      value={null}
      onChange={(evt, value) => {
        handleStateSelect(value);
      }}
      sx={{ paddingTop: 1, marginTop: 2 }}
      blurOnSelect
    />
  );
};

USStateSelector.propTypes = {
  /** callback for the selection event, expecting the esri Graphic for the selected state */
  onSelectGraphic: PropTypes.func.isRequired
}

export default USStateSelector