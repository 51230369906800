import React from 'react';
import * as PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import questionModules from '../utils/Questions';
import { QUESTION_UNKNOWN_VALUE, QUESTION_DISABLED_VALUE, INSUFFICIENT_EVIDENCE } from '../utils/Consts';
import { floatApproxEquals } from '../utils/Utils';
import ModuleSummaryRow from './ModuleSummaryRow';

function ModuleSummary({ surveyAnswers, exposureResults }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <ModuleSummaryRow
            module="A"
            formalName="exposureToLocalClimateChange"
            percentAnswered={exposureResults[0] === INSUFFICIENT_EVIDENCE ? 0 : 100}
          />
          {Object.entries(questionModules).map((entry) => {
            const [module, { questions, formalName }] = entry;
            let totalQuestions = questions.length;
            let answeredQuestions = 0.0;
            questions.forEach(
              (question) => {
                const questionLabel = question.label;
                if (surveyAnswers[questionLabel] === QUESTION_DISABLED_VALUE) {
                  totalQuestions -= 1;
                } else if (surveyAnswers[questionLabel] !== QUESTION_UNKNOWN_VALUE) {
                  answeredQuestions += 1.0;
                }
              },
            );
            let percentAnswered = (answeredQuestions / totalQuestions) * 100;
            if (floatApproxEquals(percentAnswered, 100)) {
              percentAnswered = 100;
            } else if (floatApproxEquals(percentAnswered, 0)) {
              percentAnswered = 0;
            } else {
              percentAnswered = percentAnswered.toFixed(0);
            }
            return (
              <ModuleSummaryRow
                key={module}
                module={module}
                formalName={formalName}
                percentAnswered={percentAnswered}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ModuleSummary.propTypes = {
  surveyAnswers: PropTypes.objectOf(PropTypes.number).isRequired,
  exposureResults: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default ModuleSummary;
