import {
  createContext, useCallback, useContext, useEffect, useState
} from 'react';
import * as PropTypes from 'prop-types';
import jwt_decode from 'jwt-decode';

const AuthContext = createContext({
  jwtToken: '',
  email: '',
  authenticate: () => { },
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

let authCodeChecked = false;
let codeUsed = false;

export function AuthProvider({ children }) {
  const [jwtToken, setJwtToken] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (authCodeChecked) {
      if (!jwtToken && window.localStorage.jwtToken && !email && window.localStorage.email) {
        setJwtToken(window.localStorage.jwtToken);
        setEmail(window.localStorage.email);
      }
      return;
    }

    const tryToLogin = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      let possibleJwtToken = window.localStorage.jwtToken;
      let expiration = possibleJwtToken ? jwt_decode(possibleJwtToken).exp : 0;
      if (code && !codeUsed && (!possibleJwtToken || expiration < Date.now() / 1000)) {
        codeUsed = true;
        const response = await fetch(`${process.env.REACT_APP_CCVI_API_URL}/auth/auth-token-from-code?redirect_uri=${window.location.origin}&code=${code}`);
        if (!response.ok) {
          return;
        }
        const body = await response.json();
        window.localStorage.jwtToken = body.accessToken;
      }

      possibleJwtToken = window.localStorage.jwtToken;

      if (possibleJwtToken) {
        const jwtTokenDecoded = jwt_decode(possibleJwtToken);
        setJwtToken(possibleJwtToken);

        const response = await fetch(`${process.env.REACT_APP_CCVI_API_URL}/auth/get-user-email`, { headers: { 'Authorization': `Bearer ${possibleJwtToken}` } });
        let newEmail;
        if (!response.ok) {
          // TODO: properly handle when the user email cannot be retrieved or update
          // the sent back JWT token to use the .sub property as the user email so
          // we don't need to make this call.
          newEmail = 'Error getting Email';
        } else {
          newEmail = await response.text();
          window.localStorage.email = newEmail;
          
        }
        setEmail(newEmail);
        expiration = jwtTokenDecoded.exp;
        setTimeout(() => {
          setJwtToken('');
          setEmail('');
          window.localStorage.removeItem('jwtToken');
          window.localStorage.removeItem('email');
        }, expiration * 1000 - Date.now());
      }
    };

    authCodeChecked = true;
    tryToLogin();
  }, [email, jwtToken]);

  const authenticate = useCallback(async () => {
    const response = await fetch(`${process.env.REACT_APP_CCVI_API_URL}/auth/auth-url/?redirect_uri=${window.location.origin}&scope=email%20openid&state=`);
    const loginUrl = await response.text();
    window.location.href = loginUrl;

  }, []);

  const value = {
    jwtToken,
    email,
    authenticate,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
