import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

i18n.use(LanguageDetector).use(XHR).init({
  fallbackLng: 'en',
  load: 'languageOnly',
  ns: 'translations',
  debug: false,

  interpolation: {
    escapeValue: false,
  },

  react: {
    useSuspense: true,
  },

  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    withCredentials: false,
  },
});

export default i18n;
