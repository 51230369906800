import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { INSUFFICIENT_EVIDENCE, RCP_VALUES } from '../utils/Consts';
import { calculateModuleKCategory, calculateOverallCcviText } from '../utils/Utils';
import './Scoring.css';
import { Tooltip } from '@mui/material';

function Scoring({ t, exposureResults, climateExposureModifier, adaptiveCapacity, adaptiveCapacityModifier, traitScores, observedModeledResponseScore }) {
  let alerts = [];
  if (exposureResults[0] === INSUFFICIENT_EVIDENCE) {
    alerts.push(<Alert key='climateExposure' severity='error'>{t('scoring.noClimateExposure')}</Alert>);
  }

  if (climateExposureModifier === INSUFFICIENT_EVIDENCE) {
    alerts.push(<Alert key='climateExposureModifier' severity='error'>{t('scoring.noClimateExposureModifier')}</Alert>);
  }

  if (adaptiveCapacity === INSUFFICIENT_EVIDENCE) {
    alerts.push(<Alert key='adaptiveCapacity' severity='error'>{t('scoring.noAdaptiveCapacity')}</Alert>);
  }

  if (adaptiveCapacityModifier === INSUFFICIENT_EVIDENCE) {
    alerts.push(<Alert key='adaptiveCapacityModifier' severity='error'>{t('scoring.noAdaptiveCapacityModifier')}</Alert>);
  }

  const getRelativeClimateExposure = (climateExposure) => {
    if (climateExposure === INSUFFICIENT_EVIDENCE) return t('scoring.insufficientEvidence');
    if (climateExposure < 0.4) return t('scoring.climateLower');
    if (climateExposure < 0.53) return t('scoring.climateAverage');
    return t('scoring.climateHigher');
  };

  const getRelativeAdaptiveCapacity = (adaptiveCapacity) => {
    if (adaptiveCapacity === INSUFFICIENT_EVIDENCE) return t('scoring.insufficientEvidence');
    if (adaptiveCapacity <= 2.5) return t('scoring.acHigh');
    if (adaptiveCapacity <= 3) return t('scoring.acModeratelyHigh');
    if (adaptiveCapacity <= 3.6) return t('scoring.acModeratelyLow');
    return t('scoring.acLow');
  }

  const adaptiveCapacityTooltip = adaptiveCapacity === INSUFFICIENT_EVIDENCE ?
    t('scoring.noAdaptiveCapacity')
    : adaptiveCapacity.toFixed(2);

  return <>
    {alerts.length !== 0
      ? <Stack>
        {alerts}
      </Stack>
      : null}
    <TableContainer component={Paper}>
      <Table sx={{ tableLayout: 'fixed' }}>
        <TableBody>
          <TableRow>
            <TableCell align='right' sx={{ fontWeight: 'bold' }}>{t('scoring.adaptiveCapacity')}:</TableCell>
            <TableCell align='left'>
              <Tooltip title={adaptiveCapacityTooltip}>
                {getRelativeAdaptiveCapacity(adaptiveCapacity)}
              </Tooltip>
            </TableCell>
          </TableRow>
          {exposureResults.map((climateExposure, idx) => {
            const relativeClimateExposure = getRelativeClimateExposure(climateExposure);
            const climateExposureTooltip = climateExposure === INSUFFICIENT_EVIDENCE ?
              t('scoring.noClimateExposure')
              : climateExposure.toFixed(2);

            const overallScore = calculateOverallCcviText(traitScores[idx], observedModeledResponseScore);
            const moduleKCategory = calculateModuleKCategory(observedModeledResponseScore);
            const overallTooltip = traitScores[idx] !== INSUFFICIENT_EVIDENCE && (
              <div>
                <div>{`${t('scoring.vulnerabilitySubscore')}: ${traitScores[idx].toFixed(2)}`}</div>
                <div>{`${t('scoring.moduleKCategory')}: ${moduleKCategory}`}</div>
              </div>
            );
            return (
              <Fragment key={RCP_VALUES[idx]}>
                <TableRow>
                  <TableCell align='center' colSpan={2} sx={{ fontWeight: 'bold' }}>
                   {t(`scoring.${RCP_VALUES[idx]}`)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='right' sx={{ fontWeight: 'bold' }}>{t('scoring.exposure')}:</TableCell>
                  <TableCell align='left'>
                    <Tooltip title={climateExposureTooltip}>
                      {relativeClimateExposure}
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='right' sx={{ fontWeight: 'bold' }}>{t('scoring.overallScore')}:</TableCell>
                  <TableCell align='left'>
                    <Tooltip title={overallTooltip} >
                      {t(`scoring.${overallScore}`)}
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer >
  </>;
}

Scoring.propTypes = {
  t: PropTypes.func.isRequired,
  exposureResults: PropTypes.arrayOf(PropTypes.number).isRequired,
  climateExposureModifier: PropTypes.number.isRequired,
  adaptiveCapacity: PropTypes.number.isRequired,
  adaptiveCapacityModifier: PropTypes.number.isRequired,
  traitScores: PropTypes.arrayOf(PropTypes.number).isRequired,
  observedModeledResponseScore: PropTypes.number.isRequired,
};

export default withTranslation('translations')(Scoring);
