import { INSUFFICIENT_EVIDENCE } from "./Consts";

const floatApproxEquals = (x, y, epsilon = 0.001) => {
  const diff = Math.abs(x - y);
  return diff <= epsilon;
};

const calculateScoreIndexViaThresholds = (score, thresholds) => {
  if (score === INSUFFICIENT_EVIDENCE) {
    return scoringMatrix.length - 1;
  }

  for (let i = 0; i < thresholds.length && i < 4; i++) {
    const threshold = thresholds[i];
    if (score <= threshold) {
      // -2 to avoid IE column.
      return scoringMatrix.length - 2 - i;
    }
  }

  // If we somehow didn't return on the last threshold, then it must be EV.
  return 0;
};


// Rows represent high to low (and then insufficient) scores for observedResponseScore.
// Columns represent high to low (and then insufficient) scores for traitScore.
// Anything in the last column gets overidden as "insufficientEvidence", since we require
// a valid trait score to determine the overall score:
const scoringMatrix = [
  ["EV", "EV", "HV", "HV", "EV"],
  ["EV", "HV", "HV", "MV", "HV"],
  ["HV", "HV", "MV", "LV", "MV"],
  ["HV", "MV", "MV", "LV", "LV"],
  ["EV", "HV", "MV", "LV", "insufficientEvidence"]
];

const calculateOverallCcviText = (traitScore, observedModeledResponseScore) => {
  // Comment out to allow calculation with observedModeledResponseScore alone:
  if (traitScore === INSUFFICIENT_EVIDENCE) return "insufficientEvidence";
  const traitIndex = calculateScoreIndexViaThresholds(traitScore, [5.23, 6.33, 6.95, 28.45]);
  const observedModeledResponseIndex = calculateScoreIndexViaThresholds(observedModeledResponseScore, [0.1667, 0.5, 0.8335, 1]);
  return scoringMatrix[observedModeledResponseIndex][traitIndex];
};

const calculateModuleKCategory = (observedModeledResponseScore) => {
  if (observedModeledResponseScore === INSUFFICIENT_EVIDENCE) return 'IE';
  const observedModeledResponseIndex = calculateScoreIndexViaThresholds(observedModeledResponseScore, [0.1667, 0.5, 0.8335, 1]);
  return scoringMatrix[observedModeledResponseIndex][4]; // the last column only takes into account observed modeled response.
}

export { calculateOverallCcviText, floatApproxEquals, calculateModuleKCategory };
