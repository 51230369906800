import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { withTranslation } from 'react-i18next';
import './ModuleSummaryRow.css';

function ModuleSummaryRow({ module, formalName, percentAnswered, t }) {
  return (
    <TableRow key={module}>
      <TableCell align='center'>
        {`${t('tabBar.module', { section: module })} / ${t(`summary.${formalName}`)}`}
      </TableCell>
      <TableCell
        align='center'
        className={classNames(percentAnswered === 100 && 'allModuleQuestionsAnswered', percentAnswered === 0 && 'noModuleQuestionsAnswered')}
      >
        {percentAnswered}
        %
      </TableCell>
    </TableRow>
  );
}

ModuleSummaryRow.propTypes = {
  t: PropTypes.func.isRequired,
  module: PropTypes.string,
  formalName: PropTypes.string,
  percentAnswered: PropTypes.number
}
export default withTranslation('translations')(ModuleSummaryRow);
