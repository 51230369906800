import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import './TitleBar.css';
import { useAuth } from '../AuthContext';
import LoadDialog from './TitleBarItems/LoadDialog';
import SaveDialog from './TitleBarItems/SaveDialog';
import ShareDialog from './TitleBarItems/ShareDialog';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';

// TODO: Refactor TitleBar so it doesn't contain the "dialogs"; it just takes callbacks to open them:
function TitleBar({
  t, globalAssessmentState, graphicsLayer, setAssessmentId, setSavedAuthor, setAllFormState,
}) {
  const { email, authenticate } = useAuth();

  const [existingAssessment, setExistingAssessment] = useState(false);

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [loadDialogOpen, setLoadDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const [sharedUsers, setSharedUsers] = useState([]);

  const titlebarSx = {
    color: 'white',
    ':disabled': { color: 'rgba(255,255,255,0.26)' }
  };

  return (
    <AppBar position='static'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Typography
            variant='h6'
            noWrap
            sx={{ color: 'inherit'}}
          >
            <img
              src="/NatureServe-logo_H-white.png"
              alt="NatureServe"
            />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button sx={titlebarSx}>
              {t('titleBar.assessmentForm')}
            </Button>
            {email
              ? (
                <>
                  <Divider sx={{ borderColor: 'rgba(255,255,255,.5)'}} orientation='vertical' variant='middle' flexItem  />
                  <Button
                    onClick={() => setLoadDialogOpen(true)}
                    sx={titlebarSx}
                  >
                    {t('titleBar.load')}
                  </Button>
                  <LoadDialog open={loadDialogOpen} setOpen={setLoadDialogOpen} setExistingAssessment={setExistingAssessment} setAllFormState={setAllFormState} setSharedUsers={setSharedUsers} />
                  <Button
                    onClick={() => setSaveDialogOpen(true)}
                    sx={titlebarSx}
                    disabled={!globalAssessmentState.assessed.completed}
                  >
                    {t('titleBar.save')}
                  </Button>
                  <SaveDialog
                    globalAssessmentState={globalAssessmentState}
                    graphicsLayer={graphicsLayer}
                    open={saveDialogOpen}
                    setOpen={setSaveDialogOpen}
                    setAssessmentId={setAssessmentId}
                    setSavedAuthor={setSavedAuthor}
                    existingAssessment={existingAssessment}
                    setExistingAssessment={setExistingAssessment}
                    setSharedUsers={setSharedUsers}
                  />
                  <Button
                    onClick={() => setShareDialogOpen(true)}
                    sx={titlebarSx}
                    disabled={!existingAssessment}
                  >
                    {t('titleBar.share')}
                  </Button>
                  <ShareDialog
                    open={shareDialogOpen}
                    setOpen={setShareDialogOpen}
                    existingAssessment={existingAssessment}
                    sharedUsers={sharedUsers}
                    setSharedUsers={setSharedUsers}
                    author={globalAssessmentState.author}
                    assessmentId={globalAssessmentState.assessmentId}
                  />
                </>
              )
              : null}
          </Box>
          <Box sx={{ flexShrink: 1, display: { xs: 'none', md: 'flex' } }}>
            {email
              ? <Typography>{email}</Typography>
              : <Button
                onClick={() => { authenticate(); }}
                sx={titlebarSx}
              >
                {t('titleBar.login')}
              </Button>
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

TitleBar.propTypes = {
  t: PropTypes.func.isRequired,
  setAssessmentId: PropTypes.func.isRequired,
  setSavedAuthor: PropTypes.func.isRequired,
  globalAssessmentState: PropTypes.shape({
    ccviVersion: PropTypes.string.isRequired,
    assessmentId: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    assessed: PropTypes.object.isRequired,
    surveyAnswers: PropTypes.objectOf(PropTypes.number).isRequired,
    exposureResults: PropTypes.object.isRequired,
    exposureStatus: PropTypes.string.isRequired
  }).isRequired,
  graphicsLayer: PropTypes.instanceOf(GraphicsLayer).isRequired,
};

export default withTranslation('translations')(TitleBar);
