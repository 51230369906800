import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useAuth } from '../../AuthContext';


function ShareDialog({
  t, open, setOpen, existingAssessment, sharedUsers, setSharedUsers, author, assessmentId
}) {
  const { email, jwtToken } = useAuth();
  const [sharing, setSharing] = useState(false);
  const [shareErrorMessage, setShareErrorMessage] = useState('');

  const [searchField, setSearchField] = useState(sharedUsers.join(', '));
  // Update the searchField whenever the saved sharedUsers changes.
  useEffect(() => setSearchField(sharedUsers.join(', ')), [sharedUsers]);


  const share = () => {
    setShareErrorMessage('');
    setSharing(true);
    const newSharedUsers = searchField.split(',').map((user) => user.trim());
    fetch(`${process.env.REACT_APP_CCVI_API_URL}/assessment/${author}/${assessmentId}/share`,
      {
        method: 'PUT',
        headers: { Authorization: `Bearer ${jwtToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(newSharedUsers)
      })
      .then((res) => {
        if (res.ok) {
          setOpen(false);
          setSharing(false);
          setSharedUsers(newSharedUsers);
          return undefined;
        } else {
          setShareErrorMessage(`${res.status} ${res.statusText}`);
          return res.json();
        }
      })
      .then((body) => {
        // This is only used to check for error messages.
        if (!body) {
          return;
        }

        if (body.msg) {
          setShareErrorMessage((curVal) => curVal + `: ${body.msg}`);
        }
        setSharing(false);
      })
      .catch((e) => {
        setShareErrorMessage(e);
        setSharing(false);
      });
  };

  let dialogContentText = '';
  let readyToShare = false;
  if (!existingAssessment) {
    dialogContentText = 'shareDialog.cannotShareNotSaved';
  } else if (author !== email) {
    dialogContentText = 'shareDialog.cannotShareNotAuthor';
  } else {
    readyToShare = true;
    dialogContentText = 'shareDialog.shareDescription';
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='share-dialog-title'
      aria-describedby='share-dialog-description'
    >
      <DialogTitle id='share-dialog-title'>{t('shareDialog.shareAssessment')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='share-dialog-description'>
          {t(dialogContentText)}
        </DialogContentText>
        {readyToShare
          ? <TextField
            variant='outlined'
            label={t('shareDialog.shareFieldLabel')}
            fullWidth
            multiline
            margin='dense'
            value={searchField}
            onChange={(event) => setSearchField(event.target.value)} />
          : null}
      </DialogContent>
      {shareErrorMessage ? <Alert severity='error'>{t('shareDialog.couldNotShare', { error: shareErrorMessage })}</Alert> : null}
      {sharing
        ? <LinearProgress />
        : <DialogActions>
          <Button onClick={() => setOpen(false)}>{t('shareDialog.cancel')}</Button>
          <Button onClick={() => share()} disabled={!readyToShare}>{t('shareDialog.share')}</Button>
        </DialogActions>
      }
    </Dialog>
  );
}

ShareDialog.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  existingAssessment: PropTypes.bool.isRequired,
  sharedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSharedUsers: PropTypes.func.isRequired,
  author: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
};

export default withTranslation('translations')(ShareDialog);
