import React from 'react';
import * as PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText, Stack, TextField } from '@mui/material';
import { withTranslation, Trans } from 'react-i18next';
import './AssessmentForm.css';
import { kingdoms, majorTaxonomicGroups } from '../utils/SpeciesValues';
import questions from '../utils/Questions';
import { QUESTION_UNKNOWN_VALUE, QUESTION_DISABLED_VALUE } from '../utils/Consts';

function AssessmentForm({
  t, assessed, changeAssessed, changeSurveyAnswer,
}) {
  const changeKingdom = (event) => {
    changeAssessed('kingdom', kingdoms.find((kingdom) => kingdom === event.target.value));
    Object.entries(questions).forEach(
      (module) => {
        const details = module[1];
        details.questions.forEach(
          (question) => {
            // If the question depends on the kingdom, clear it because a) there might be a
            // disabled value there, b) it might be hidden/disabled entirely, and c) presumably
            // they're going to want to re-do it.
            if ('overridesByKingdom' in question) {
              const override = question.overridesByKingdom[event.target.value];
              changeSurveyAnswer(question.label, override.hideQuestion
                ? QUESTION_DISABLED_VALUE : QUESTION_UNKNOWN_VALUE);
            }
          },
        );
      },
    );
  };

  const changeNonKingdomAssessment = (assessment) => {
    return (event) => {
      changeAssessed(assessment, event.target.value);
    }
  };

  return (
    <>
      <h2>
        {t('assessmentForm.header', { version: '4.0' })}
      </h2>
      <p>
        <Trans i18nKey="assessmentForm.instructionsLink">
          <a href={process.env.REACT_APP_GUIDELINES_DOCUMENT} target="_blank" rel="noreferrer"> </a>
        </Trans>
      </p>
      <TextField
        variant='outlined'
        margin='normal'
        fullWidth
        label={t('assessmentForm.geographicArea')}
        value={assessed.geographicArea}
        onChange={changeNonKingdomAssessment('geographicArea')}
        helperText={!assessed.geographicArea && t('assessmentForm.required')}
        error={!assessed.geographicArea}
        required />
      <TextField
        variant='outlined'
        margin='normal'
        fullWidth
        label={t('assessmentForm.assessor')}
        value={assessed.assessor}
        onChange={changeNonKingdomAssessment('assessor')} />
      <Stack direction='row' spacing={2}>
        <TextField
          variant='outlined'
          margin='normal'
          fullWidth
          label={t('assessmentForm.scientificName')}
          value={assessed.scientificName}
          onChange={changeNonKingdomAssessment('scientificName')}
          helperText={!assessed.scientificName && t('assessmentForm.required')}
          error={!assessed.scientificName}
          required />
        <TextField
          variant='outlined'
          margin='normal'
          fullWidth
          label={t('assessmentForm.commonName')}
          value={assessed.commonName}
          onChange={changeNonKingdomAssessment('commonName')} />
      </Stack>
      <TextField
        variant='outlined'
        margin='normal'
        fullWidth
        label={t('assessmentForm.population')}
        value={assessed.population}
        onChange={changeNonKingdomAssessment('population')} />
      <Stack direction='row' spacing={2} mt={2}>
        <FormControl fullWidth className='assessSelect' error={!assessed.kingdom}>
          <InputLabel>{t('assessmentForm.kingdom')}</InputLabel>
          <Select
            value={assessed.kingdom}
            label={t('assessmentForm.kingdom')}
            onChange={changeKingdom}
          >
            {kingdoms.map((kingdom) => <MenuItem value={kingdom} key={kingdom}>{t(`kingdoms.${kingdom}`)}</MenuItem>)}
          </Select>
          <FormHelperText>{!assessed.kingdom && t('assessmentForm.required')}</FormHelperText>
        </FormControl>
        <TextField
          variant='outlined'
          margin='none'
          fullWidth
          label={t('assessmentForm.gRank')}
          value={assessed.gRank}
          onChange={changeNonKingdomAssessment('gRank')} />
      </Stack>
      <Stack direction='row' spacing={2} mt={2}>
        <FormControl fullWidth className='assessSelect' error={!assessed.majorTaxonomicGroup}>
          <InputLabel>{t('assessmentForm.majorTaxonomicGroup')}</InputLabel>
          <Select
            value={assessed.majorTaxonomicGroup}
            label={t('assessmentForm.majorTaxonomicGroup')}
            onChange={changeNonKingdomAssessment('majorTaxonomicGroup')}
            required
          >
            {majorTaxonomicGroups.map(
              (majorTaxonomicGroup) => (
                <MenuItem
                  value={majorTaxonomicGroup}
                  key={majorTaxonomicGroup}
                >
                  {t(`majorTaxonomicGroups.${majorTaxonomicGroup}`)}
                </MenuItem>
              ),
            )}
          </Select>
          <FormHelperText>{!assessed.majorTaxonomicGroup && t('assessmentForm.required')}</FormHelperText>
        </FormControl>
        {/* See above comment on G-Rank for more on why this is so manual. */}
        <TextField
          variant='outlined'
          margin='none'
          fullWidth
          label={t('assessmentForm.sRank')}
          value={assessed.sRank}
          onChange={changeNonKingdomAssessment('sRank')} />
      </Stack>
      <TextField
        multiline
        margin='normal'
        fullWidth
        label={t('assessmentForm.assessmentNotes')}
        value={assessed.assessmentNotes}
        onChange={changeNonKingdomAssessment('assessmentNotes')}
        placeholder={t('assessmentForm.specialMethodsSources')}
      />
    </>
  );
}

AssessmentForm.propTypes = {
  t: PropTypes.func.isRequired,
  assessed: PropTypes.exact({
    geographicArea: PropTypes.string.isRequired,
    assessor: PropTypes.string.isRequired,
    scientificName: PropTypes.string.isRequired,
    commonName: PropTypes.string.isRequired,
    population: PropTypes.string.isRequired,
    kingdom: PropTypes.string.isRequired,
    gRank: PropTypes.string.isRequired,
    majorTaxonomicGroup: PropTypes.string.isRequired,
    sRank: PropTypes.string.isRequired,
    assessmentNotes: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
  }).isRequired,
  changeAssessed: PropTypes.func.isRequired,
  changeSurveyAnswer: PropTypes.func.isRequired,
};

export default withTranslation('translations')(AssessmentForm);
