import scales from './Scales';

const questionModules = {
  B: {
    questions: [
      { label: 'exposureToSeaLevelRise' },
    ],
    scale: scales.greatlyIncreaseToNeutral,
    formalName: 'indirectExposureToClimateChange',
  },
  C: {
    questions: [
      { label: 'EOO', disallowedValues: ['M'] },
      { label: 'AOO', disallowedValues: ['M'] },
      { label: 'HS', disallowedValues: ['ML', 'MH'] },
      { label: 'CH', disallowedValues: ['ML', 'MH'] },
      { label: 'GR', disallowedValues: ['M'] },
    ],
    scale: scales.lowToHigh,
    formalName: 'distribution',
  },
  D: {
    questions: [
      {
        label: 'DS',
        overridesByKingdom: {
          animalia: { disallowedValues: ['ML', 'M', 'MH'] },
          plantae: { disallowedValues: ['M'] },
          fungae: { hideQuestion: true },
        },
      },
      { label: 'DDi', disallowedValues: ['M'] },
      { label: 'DP', disallowedValues: ['ML', 'M', 'MH'] },
      { label: 'SF', disallowedValues: ['ML', 'MH'] },
      { label: 'MF', disallowedValues: ['ML', 'M', 'MH'] },
      { label: 'Mde', disallowedValues: ['ML', 'MH'] },
      { label: 'MT', disallowedValues: ['ML', 'M', 'MH'] },
      { label: 'MDi', disallowedValues: ['ML', 'MH'] },
    ],
    scale: scales.lowToHigh,
    formalName: 'movement',
  },
  E: {
    questions: [
      { label: 'DD', disallowedValues: ['ML', 'MH'] },
      { label: 'PS', disallowedValues: ['M'] },
      { label: 'HP', disallowedValues: ['ML', 'MH'] },
    ],
    scale: scales.lowToHigh,
    formalName: 'evolutionaryPotential',
  },
  F: {
    questions: [
      { label: 'E', disallowedValues: ['ML', 'MH'] },
      { label: 'DB', disallowedValues: ['ML', 'MH'] },
      { label: 'DOS', disallowedValues: ['ML', 'MH'] },
    ],
    scale: scales.lowToHigh,
    formalName: 'ecologicalRole',
  },
  G: {
    questions: [
      { label: 'SP', disallowedValues: ['ML', 'MH'] },
      { label: 'CNB', disallowedValues: ['M'] },
      { label: 'PT', disallowedValues: ['ML', 'MH'] },
      { label: 'BRP', disallowedValues: ['ML', 'MH'] },
      { label: 'DDe', disallowedValues: ['ML', 'MH'] },
    ],
    scale: scales.lowToHigh,
    formalName: 'abioticNiche',
  },
  H: {
    questions: [
      { label: 'RP', disallowedValues: ['ML', 'MH'] },
      { label: 'RM', disallowedValues: ['ML', 'MH'] },
      {
        label: 'MS',
        overridesByKingdom: {
          animalia: { disallowedValues: ['M'] },
          plantae: { disallowedValues: ['ML', 'MH'] },
          fungae: { hideQuestion: true },
        },
      },
      { label: 'F', disallowedValues: ['ML', 'MH'] },
      {
        label: 'P',
        overridesByKingdom: {
          animalia: { disallowedValues: ['ML', 'M', 'MH'] },
          plantae: { disallowedValues: ['ML', 'MH'] },
          fungae: { hideQuestion: true },
        },
      },
      { label: 'SR', disallowedValues: ['ML', 'MH'] },
      { label: 'SD', disallowedValues: ['ML', 'MH'] },
      { label: 'PI', disallowedValues: ['ML', 'MH'] },
    ],
    scale: scales.lowToHigh,
    formalName: 'lifeHistory',
  },
  I: {
    questions: [
      { label: 'LS', disallowedValues: ['M'] },
      { label: 'GT', disallowedValues: ['M'] },
      { label: 'ASM', disallowedValues: ['ML', 'MH'] },
      { label: 'AS', disallowedValues: ['ML', 'MH'] },
      { label: 'R', disallowedValues: ['ML', 'MH'] },
    ],
    scale: scales.lowToHigh,
    formalName: 'demography',
  },
  J: {
    questions: [
      { label: 'topographicBarriers' },
      { label: 'anthropogenicBarriers' },
      { label: 'landuseChange' },
      { label: 'otherAnthropogenicThreats' },
      { label: 'otherBiologicalThreats' },
    ],
    scale: scales.greatlyIncreaseToNeutral,
    formalName: 'otherExtrinsicFactors',
  },
  K: {
    questions: [
      { label: 'recentClimateChange' },
      { label: 'modeledPopulationRangeSizeChange' },
      { label: 'modeledRangeChange' },
      { label: 'modeledAreaDistribution', disallowedValues: ['GI'] },
    ],
    scale: scales.greatlyIncreaseToNeutral,
    formalName: 'documentedOrModeledResponseToClimateChange',
  },
};

export default questionModules;
