import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as PropTypes from 'prop-types';

function AddOrReplaceDialog ({ open, onReplace, onAdd, onCancel }) {
  const [t] = useTranslation('translations');

  return (
    <Dialog open={open}>
      <DialogTitle>{t('addOrReplaceDialog.title')}</DialogTitle>
      <DialogContent>{t('addOrReplaceDialog.content')}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          {t('addOrReplaceDialog.cancel')}
        </Button>
        <Button onClick={onAdd}>
          {t('addOrReplaceDialog.addToExistingArea')}
        </Button>
        <Button onClick={onReplace}>
          {t('addOrReplaceDialog.replaceExistingArea')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddOrReplaceDialog.propTypes = {
  open: PropTypes.bool,
  onReplace: PropTypes.func,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func
}

export default AddOrReplaceDialog;