import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import HelpOutline from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import { withTranslation } from 'react-i18next';
import { INSUFFICIENT_EVIDENCE, QUESTION_UNKNOWN_VALUE } from '../../utils/Consts';
import QuestionHelpDialog from './QuestionHelpDialog';

function QuestionModule({
  t, questions, scale, assessed, surveyAnswers, changeSurveyAnswer, isAdaptiveCapacity, adaptiveCapacity
}) {
  const [helpTooltipOpen, setHelpTooltipOpen] = useState(false);
  const [helpTooltipData, setHelpTooltipData] = useState({ question: '', allowedValues: [] });

  return (
    <>
      <Box justifyContent='center' alignItems='center' display='flex'>
        <QuestionHelpDialog
          question={helpTooltipData.question}
          kingdom={assessed.kingdom}
          allowedValues={helpTooltipData.allowedValues}
          isAdaptiveCapacity={isAdaptiveCapacity}
          open={helpTooltipOpen}
          handleClose={() => { setHelpTooltipOpen(false); }}
        />
        <FormControl>
          {questions.map((question) => {
            let { disallowedValues } = question;
            if ('overridesByKingdom' in question) {
              const override = question.overridesByKingdom[assessed.kingdom];
              if (override.hideQuestion) {
                return null;
              }

              disallowedValues = override.disallowedValues;
            }

            const { label: questionLabel } = question;

            const questionTitle = t([`questions.${questionLabel}_${assessed.kingdom}.title`, `questions.${questionLabel}.title`]);
            const allowedValues = scale.options.filter((option) => !disallowedValues || !disallowedValues.includes(option));

            return (
              <div key={questionLabel}>
                <FormLabel>
                  {questionTitle}
                  <Tooltip title={t('questionForm.learnMore')}>
                    <IconButton onClick={() => { setHelpTooltipData({ question: questionLabel, allowedValues }); setHelpTooltipOpen(true); }}>
                      <HelpOutline />
                    </IconButton>
                  </Tooltip>
                </FormLabel>
                <RadioGroup
                  row
                  name='position'
                  value={questionLabel in surveyAnswers
                    ? surveyAnswers[questionLabel]
                    : QUESTION_UNKNOWN_VALUE}
                  onChange={
                    (_, value) => {
                      changeSurveyAnswer(
                        questionLabel,
                        Number(value),
                      );
                    }
                  }
                >
                  {scale.options.map((scoreLabel, scoreIndex) => (
                    <FormControlLabel
                      key={scoreLabel}
                      value={scale.options.length - scoreIndex - 1}
                      control={<Radio />}
                      label={t(`scales.${scoreLabel}`)}
                      labelPlacement='top'
                      disabled={disallowedValues && disallowedValues.includes(scoreLabel)}
                    />
                  ))}
                  <FormControlLabel
                    value={QUESTION_UNKNOWN_VALUE}
                    control={<Radio />}
                    label={isAdaptiveCapacity ? t('questionForm.unknownNa') : t('questionForm.unknown')}
                    labelPlacement='top'
                  />
                </RadioGroup>
              </div>
            );
          })}
        </FormControl>
      </Box >
      {
        isAdaptiveCapacity
          ? <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table sx={{ tableLayout: 'fixed' }}>
              <TableBody>
                <TableRow>
                  <TableCell align='right' sx={{ fontWeight: 600 }}>{t('scoring.adaptiveCapacity')}:</TableCell>
                  <TableCell align='left'>{adaptiveCapacity === INSUFFICIENT_EVIDENCE ? t('scoring.insufficientEvidence') : adaptiveCapacity.toFixed(2)}</TableCell>
                </TableRow>
              </TableBody>
            </Table >
          </TableContainer >
          : null
      }
    </>
  );
}

QuestionModule.propTypes = {
  t: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.exact({
    label: PropTypes.string.isRequired,
    disallowedValues: PropTypes.arrayOf(PropTypes.string),
    overridesByKingdom: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.exact({ disallowedValues: PropTypes.arrayOf(PropTypes.string) }),
        PropTypes.exact({ hideQuestion: PropTypes.bool }),
      ]),
    ),
  })).isRequired,
  scale: PropTypes.exact({
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  assessed: PropTypes.exact({
    geographicArea: PropTypes.string.isRequired,
    assessor: PropTypes.string.isRequired,
    scientificName: PropTypes.string.isRequired,
    commonName: PropTypes.string.isRequired,
    population: PropTypes.string.isRequired,
    kingdom: PropTypes.string.isRequired,
    gRank: PropTypes.string.isRequired,
    majorTaxonomicGroup: PropTypes.string.isRequired,
    sRank: PropTypes.string.isRequired,
    assessmentNotes: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
  }).isRequired,
  surveyAnswers: PropTypes.objectOf(PropTypes.number).isRequired,
  changeSurveyAnswer: PropTypes.func.isRequired,
  isAdaptiveCapacity: PropTypes.bool.isRequired,
  adaptiveCapacity: PropTypes.number.isRequired,
};

export default withTranslation('translations')(QuestionModule);
