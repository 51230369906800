import * as PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import FeatureSet from "@arcgis/core/rest/support/FeatureSet";
import * as geoprocessor from "@arcgis/core/rest/geoprocessor";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine.js";
import Graphic from "@arcgis/core/Graphic.js";
import Collection from "@arcgis/core/core/Collection.js";
import { Alert } from '@mui/material';

import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import Tooltip from '@mui/material/Tooltip';
import { exposureStatuses } from '../../../utils/Consts';
import { INSUFFICIENT_EVIDENCE, RCP_VALUES } from '../../../utils/Consts';


const geoprocessUrl = process.env.REACT_APP_RCPAVERAGE_GEOPROCESS_URL;
function Exposure ({
  t,
  onGetAllResults,
  exposureResults,
  graphicsCollection,
  status,
  setStatus
}) {
  const getExposureDisplayValue = (climateExposure) => {
    if (status === exposureStatuses.CALCULATING) return <CircularProgress />;
    if (climateExposure === INSUFFICIENT_EVIDENCE) return (
      <span className="error-color">{t('exposure.notYetCalculated')}</span>
    );
    return climateExposure.toFixed(2);
  }

  const calculateExposure = () => {
    setStatus(exposureStatuses.CALCULATING);
    if (!!graphicsCollection.length) {
      const allGeometries = graphicsCollection.toArray().map(graphic => graphic.geometry);
      const combinedGraphic = new Graphic({ geometry: geometryEngine.union(allGeometries) });
      const featureSet = new FeatureSet({ features: [combinedGraphic] });
      geoprocessor.submitJob(
        geoprocessUrl,
        { Feature_Class: featureSet }
      )
      .then((jobInfo) => {
        jobInfo.waitForJobCompletion({ interval: 1500 }).then(() => {
          if (jobInfo.jobStatus === 'job-succeeded') {
            const resultPromises = RCP_VALUES.map((rcpValue) => jobInfo.fetchResultData(rcpValue));
            Promise.all(resultPromises).then((allResults) => {
              setStatus((curStatus) => {
                // if status was changed during the calculation, don't change it to a 'Success':
                if (curStatus === exposureStatuses.CALCULATING) {
                  return exposureStatuses.SUCCESSFULLY_CALCULATED;
                }
                return curStatus;
              });
              onGetAllResults(allResults);
            });
          } else {
            setStatus(exposureStatuses.FAILED_CALCULATION);
          }
        })
        .catch((error) => {
          setStatus(exposureStatuses.FAILED_CALCULATION);
          console.error(error);
        });
      })
      .catch((error) => {
        setStatus(exposureStatuses.FAILED_CALCULATION);
        console.error(error);
      });
    }

  };
  const exposureIsOutdated = (
    exposureResults[0] !== INSUFFICIENT_EVIDENCE &&
    status !== exposureStatuses.CALCULATING &&
    status !== exposureStatuses.SUCCESSFULLY_CALCULATED
  )

  return (
    <Stack>
      <Typography variant="h6">{t('exposure.title')}:</Typography>
      {exposureResults.map((result, idx) => (
        <Stack key={idx} direction='row' spacing={2} sx={{ alignItems: 'center', margin: '12px 0' }}>
          <div>
            {t(`exposure.${RCP_VALUES[idx]}`)}:
          </div>
          <div>
            {getExposureDisplayValue(result)}
            {exposureIsOutdated && (
              <Tooltip title={t('exposure.recalculateTooltip')}>
                <WarningIcon color="warning" sx={{ verticalAlign: 'bottom', marginLeft: 1 }} />
              </Tooltip>
            )}
          </div>
        </Stack>
      ))}
      {status === exposureStatuses.FAILED_CALCULATION && (
        <Alert severity="error">{t('exposure.failed')}</Alert>
      )}
      <Button
        disabled={status !== exposureStatuses.READY_TO_CALCULATE}
        variant='contained'
        onClick={calculateExposure}
      >
        {t('exposure.calculate')}
      </Button>
    </Stack>
  );
}

Exposure.propTypes = {
  t: PropTypes.func.isRequired,
  onGetAllResults: PropTypes.func.isRequired,
  exposureResults: PropTypes.arrayOf(PropTypes.number).isRequired,
  graphicsCollection: PropTypes.instanceOf(Collection).isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired
};

export default withTranslation('translations')(Exposure);
