const scales = {
  greatlyIncreaseToNeutral: {
    options: ['GI', 'I', 'SI', 'N'],
  },
  lowToHigh: {
    options: ['L', 'ML', 'M', 'MH', 'H'],
  },
};

export default scales;
