import React from 'react';
import * as PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { withTranslation } from 'react-i18next';

function PreviousNextButtons({
  t, tabCount, tabIndex, setTabIndex, cannotContinue
}) {
  return (
    <Stack direction='row' spacing={5} justifyContent='center' mt={2}>
      <Button
        variant='outlined'
        startIcon={<NavigateBefore />}
        disabled={tabIndex === 0}
        onClick={() => setTabIndex(tabIndex - 1)}
      >
        {t('footer.previousTab')}
      </Button>
      <Button
        variant='outlined'
        endIcon={<NavigateNext />}
        disabled={cannotContinue || tabIndex === tabCount - 1}
        onClick={() => setTabIndex(tabIndex + 1)}
      >
        {t('footer.nextTab')}
      </Button>
    </Stack>
  );
}

PreviousNextButtons.propTypes = {
  t: PropTypes.func.isRequired,
  tabCount: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
  cannotContinue: PropTypes.bool.isRequired,
};

export default withTranslation('translations')(PreviousNextButtons);
